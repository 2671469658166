.cart {
  padding: 1rem;
  margin-top: 8rem;
  
  

  button {
    background-color: #fbd815;
    width: 13rem;
    padding: .5rem;
    margin-top: 1em;
    font-size: 1.1em;
    border-radius: 25px;
    border-color: #D5D9D9;
    border-style: solid;
    border-width: 1px;

    &:hover:not([disabled]) {
      background-color: #eecf1d;
      cursor: pointer;
    }

    &:disabled {
      opacity: .5;
      background-color: lightgray;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    

    .product {
      display: flex;
      border-top: 1px dotted;
      border-left: 1px dotted;
      border-right: 1px dotted;
      padding: .3rem .5rem;
      align-items: center;

      h3 {
        color: #007185;
        font-weight: 700;
        font-size: 1em;
        line-height: 20px;
        margin: .3rem;
        flex: 1;
      }

      img {
        max-width: 3rem;
        height: auto;
        margin: .875rem;
      }
      
    }
  }
}
