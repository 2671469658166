.productPage {
  margin-top: .2rem;
  .heading{
    display: flex;
    background-color: rgb(232, 223, 223);
    justify-content: center;
    margin-bottom: 20px;
    color:#066053;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .product {
      
      padding: 20px;
      flex-basis: 30.33%;
      margin-bottom: 5rem;
      text-align: center;
      justify-items: center;
      .pic{
        border-bottom: 3px solid rgb(121, 119, 122);
        padding: 3px;
        margin: 5px;
      }
      h5 {
        padding:5px;
        color: #007185;
        font-size:1rem;
        line-height: 20px;
        margin: 0;
      }

      img {
        height: 12rem;
      }
    
      button {
        background-color: #fbd815;
        width: 7rem;
        padding: .5rem;
        font-size: 1.1em;
        border-radius: 25px;
        border-color: #D5D9D9;
        border-style: solid;
        border-width: 1px;

        &:hover:not([disabled]) {
          background-color: #eecf1d;
          cursor: pointer;
        }

        &:disabled {
          opacity: .5;
          background-color: lightgray;
        }
      }
    }

    @media (max-width: 767px) {
      .product {
        flex-basis: 50%;
      }
    }

    @media (max-width: 400px) {
      .product {
        flex-basis: 100%;
      }
    }
  }
}

.error {
  color: red;
  text-align: center;
}
