.quantifier {
    display: flex;
    align-items: center;
  
    input {
      order: 1;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      margin: 0;
      outline: none;
      padding: 10px;
      position: relative;
      height: 30px;
      text-align: center;
      width: 30px;
      display: inline-block;
      font-size: 13px;
      resize: vertical;
    }
  
    input[type="button"] {
      -webkit-appearance: button;
      cursor: pointer;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  
    input[type='button'] {
      background-color: #eeeeee;
      border: none;
      width: 30px;
      transition: all 300ms ease;
      font-weight: bold;
      font-size: 1.2em;
      height: 30px;
      padding: 0;
      position: relative;
  
      &:hover {
        background-color: #D5D9D9;
      }
    }
  
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
    }
  }