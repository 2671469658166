.carousel-container {
  display: flex;
  align-items: center;
  padding: 1em;
}
.carousel-container2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1em;
  gap: 1em;
}
@media (max-width: 767px) {
  .carousel-container2 {
    width: fit-content;
    flex-direction: column;
  }
  .carousel-container {
    width: fit-content;
  }
}
.carousel .slide .legend {
  background-color: rgb(195, 193, 191);
}
