/* UTILITIES */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family:
    Andale Mono,
    monospace;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
/* NAVBAR STYLING STARTS */
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: black;
  color: #fff;
  z-index: auto;
}
.NavLink {
  display: flex;
  justify-content: center;
}
.nav-links a {
  color: #fff;
  text-align: center;
}

/* LOGO */
.logo {
  font-size: 32px;
}

/* NAVBAR MENU */
.menu {
  display: flex;
  gap: 1em;
  font-size: 18px;
}

.menu li:hover {
  background-color: rgb(255, 195, 0);
  border-radius: 5px;
  transition: 0.3s ease;
}

.menu li {
  padding: 5px 14px;
}

/* DROPDOWN MENU */
.services {
  position: relative;
}

.dropdown {
  background-color: black;
  padding: 1em 0;
  position: absolute; /*WITH RESPECT TO PARENT*/
  display: none;
  border-radius: 8px;
  top: 35px;
  z-index: 2;
}

.dropdown li + li {
  margin-top: 10px;
}

.dropdown li {
  padding: 0.5em 1em;
  width: 8em;
  text-align: center;
}

.dropdown li:hover {
  background-color: rgb(255, 195, 0);
}

.services:hover .dropdown {
  display: block;
}
/* RESPONSIVE NAVBAR MENU STARTS */

/* CHECKBOX HACK */

input[type='checkbox'] {
  display: none;
}

/* HAMBURGER MENU */
.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
}
/* .logo {
     height: 1.5em;
    padding: 0.5em;
    will-change: filter;
    transition: filter 300ms;
    transform: scaleX(-1);


     &:hover {
      filter: drop-shadow(0 0 2em #646cffaa);
     }
   }   */

.cartwidget {
  display: flex;
  width: 1.5em;
  flex-direction: row;
  justify-content: center;
}
.productsCount {
  z-index: 1;
  font-size: 1em;
  top: 38px;
  color: orange;
}
.logo {
  /* padding: 1.5rem .4rem;*/
  will-change: filter;
  transition: filter 300ms;
}

/* APPLYING MEDIA QUERIES */
@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    background-color: black;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
    z-index: 3;
  }

  .menu li:hover {
    display: inline-block;
    background-color: rgb(255, 195, 0);
    transition: 0.3s ease;
  }

  .menu li + li {
    margin-top: 12px;
  }

  input[type='checkbox']:checked ~ .menu {
    display: block;
  }

  .hamburger {
    display: block;
  }

  .dropdown {
    left: 35%;
    top: 30px;
    transform: translateX(35%);
    z-index: 2;
  }

  .dropdown li:hover {
    background-color: rgb(255, 195, 0);
  }
}
