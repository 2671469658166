.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-body {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 5%;
    top: 20%;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // display: flex;
    // flex-direction: column;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;

    form{
        display: flex;
        flex-direction: column;
    }
   
    
   

    input[type= "text"],input[type= "e-mail"],input[type= 'number'], select, textarea {
        width: 100%; /* Full width */
        padding: 12px; /* Some padding */ 
        border: 1px solid #ccc; /* Gray border */
        border-radius: 4px; /* Rounded borders */
        box-sizing: border-box; /* Make sure that padding and width stays in place */
        margin-top: 6px; /* Add a top margin */
        margin-bottom: 16px; /* Bottom margin */
        resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
      }
      
     
      
     
      .button_container{
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 1em;
        gap: 1em;
      }

      @media (max-width: 768px) {
        .button_container{
          display: flex;
          justify-content: center;
          padding: 1em;
          flex-direction: column;

        }
    
      }
      button, input[type=submit] {
        background-color: #fbd815;
        width: 13rem;
        padding: .5rem;
        margin-top: 1em;
        font-size: 1.1em;
        border-radius: 25px;
        border-color: #D5D9D9;
        border-style: solid;
        border-width: 1px;
    
        &:hover:not([disabled]) {
          background-color: #eecf1d;
          cursor: pointer;
        }
    
        &:disabled {
          opacity: .5;
          background-color: lightgray;
        }
      }
}
@media (max-width: 768px) {
    .popup-body{
        left: 0%;
        right: 0%;
        bottom: 0%;
        top: 0%;
    }
}