caption {
  color: brown;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  justify-content: center;
  width: 100%;
  height: auto;
  text-align: center;
}
caption h2 {
  padding: 10px;
}
