.product_detail {
  display: flex;
  flex-direction:row;
  width:80%;
  align-items: center;
  padding: 1em;
 
  gap: 1em;
}
.productdetail{
  display:flex;
  flex-direction: column;
}


.button_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1em;
  gap: 1em;
}
@media (max-width: 768px) {
  .button_container {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    flex-direction: column;
  }
}
.button_style {
  background-color: #fbd815;
  width: 13rem;
  padding: 0.5rem;
  font-size: 1.1em;
  border-radius: 25px;
  border-color: #d5d9d9;
  border-style: solid;
  border-width: 1px;

  &:hover:not([disabled]) {
    background-color: #eecf1d;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    background-color: lightgray;
  }
}

@media screen and (max-width:750px){
  .product_detail{
    flex-direction: column;
    width:100%;
  }
}